<template>
  <div>
    <el-form
      ref="form"
      :model="value"
      label-width="0"
      :rules="rules"
    >
      <el-row style="margin-bottom: 10px">
        <el-col
          :span="6"
          style="line-height: 40px"
          >开始时间：</el-col
        >
        <el-col :span="18">
          <el-form-item
            label=""
            prop="startTime"
          >
            <el-date-picker
              v-model="value.startTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择开始日期"
              :picker-options="pickerOptions"
              @change="setDate"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :span="6"
          style="line-height: 40px"
          >结束时间：</el-col
        >
        <el-col :span="18">
          <el-form-item
            label=""
            prop="endTime"
          >
            <el-date-picker
              v-model="value.endTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择结束日期"
              @change="setDate"
              :picker-options="pickerOptions1"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      pickerOptions1: {
        disabledDate: this.disabledDate,
      },
      rules: {
        startTime: [{ required: true, message: '请选择开始时间', trigger: ['change', 'blur'] }],
        endTime: [
          {
            validator: (rule, value, callback) => {
              if (this.value.budgetType == 'LIFETIME_BUDGET') {
                if (!value) {
                  return callback('预算类型是总预算的时候,结束时间必填');
                }
                if (new Date(value).getTime() < new Date(this.value.startTime).getTime()) {
                  return callback('结束时间不能小于开始时间');
                } else {
                  callback();
                }
              }
              callback();
            },
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  methods: {
    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7 || time.getTime() < new Date(this.value.startTime).getTime();
    },
    setDate() {
      this.$emit('input', this.value);
    },
  },
};
</script>
<style lang="scss" scoped></style>
