<template>
  <div>
    <el-dialog
      :title="`批量修改${level == 'adset' ? '广告组' : '广告'}名称`"
      :visible="editNameShow"
      width="800px"
      @close="cancle"
    >
      <el-form
        :rules="rules"
        :model="formModel"
      >
        <el-form-item
          :label="`${level == 'adset' ? '广告组' : '广告'}名称`"
          label-width="120px"
          prop="name"
        >
          <el-input v-model="formModel.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancle">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    level: {
      type: String,
      default: 'adset',
    },
    editNameShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formModel: {
        name: '',
      },
      rules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change'] }],
      },
    };
  },
  methods: {
    cancle() {
      this.$emit('update:editNameShow', false);
    },
    sure() {
      this.$emit('setName', this.formModel.name, this.level);
      this.cancle();
    },
  },
};
</script>
<style lang="scss" scoped></style>
