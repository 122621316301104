// 默认受众
export const getAudienceDefault = () => {
  return {
    audienceSize: {
      success: true,
    },
    geos: null, // 地区
    minAge: '13', // 最小年纪
    maxAge: '50+', // 最大年纪
    gender: 'OTHER', // 性别
    languages: null, // 语言
    interests: {
      custom: [],
    }, // 预设受众
    segments: [
      {
        // 自定义受众
        segmentId: [],
        operation: 'INCLUDE',
      },
      {
        segmentId: [],
        operation: 'EXCLUDE',
      },
    ], // 自定义受众
    autoExpansionOptions: true, //自动扩展受众
  };
};
// 年纪列表
export const minAgeList = [
  { name: '13', value: '13' },
  { name: '14', value: '14' },
  { name: '15', value: '15' },
  { name: '16', value: '16' },
  { name: '17', value: '17' },
  { name: '18', value: '18' },
  { name: '19', value: '19' },
  { name: '20', value: '20' },
  { name: '21', value: '21' },
  { name: '22', value: '22' },
  { name: '23', value: '23' },
  { name: '24', value: '24' },
  { name: '25', value: '25' },
  { name: '26', value: '26' },
  { name: '27', value: '27' },
  { name: '28', value: '28' },
  { name: '29', value: '29' },
  { name: '30', value: '30' },
  { name: '31', value: '31' },
  { name: '32', value: '32' },
  { name: '33', value: '33' },
  { name: '34', value: '34' },
  { name: '35', value: '35' },
];
export const ageList = [
  { name: '13', value: '13' },
  { name: '14', value: '14' },
  { name: '15', value: '15' },
  { name: '16', value: '16' },
  { name: '17', value: '17' },
  { name: '18', value: '18' },
  { name: '19', value: '19' },
  { name: '20', value: '20' },
  { name: '21', value: '21' },
  { name: '22', value: '22' },
  { name: '23', value: '23' },
  { name: '24', value: '24' },
  { name: '25', value: '25' },
  { name: '26', value: '26' },
  { name: '27', value: '27' },
  { name: '28', value: '28' },
  { name: '29', value: '29' },
  { name: '30', value: '30' },
  { name: '31', value: '31' },
  { name: '32', value: '32' },
  { name: '33', value: '33' },
  { name: '34', value: '34' },
  { name: '35', value: '35' },
  { name: '36', value: '36' },
  { name: '37', value: '37' },
  { name: '38', value: '38' },
  { name: '39', value: '39' },
  { name: '40', value: '40' },
  { name: '41', value: '41' },
  { name: '42', value: '42' },
  { name: '43', value: '43' },
  { name: '44', value: '44' },
  { name: '45', value: '45' },
  { name: '46', value: '46' },
  { name: '47', value: '47' },
  { name: '48', value: '48' },
  { name: '49', value: '49' },
  { name: '50+', value: '50+' },
];

export const regUrl =
  /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(\:[0-9]+)?)(\/[a-zA-Z0-9%_.~+-]+)*\/?(\?[\u4E00-\u9FA5\w%_.,~+-=&{}]*)?(#[\u4E00-\u9FA5\w_-{}]+)?$/;
