<template>
  <span>
    <span>
      <div v-if="edit">
        <el-input
          v-model="value.budgetAmount"
          @change="changeBudget"
          ref="input"
          @blur="edit = false"
          type="number"
        >
          >
        </el-input>
      </div>
      <div v-else>
        <p>
          {{ value.budgetAmount
          }}<el-button
            type="text"
            icon="el-icon-edit"
            @click="editBudget"
            v-if="!edit"
          ></el-button>
        </p>
        <p class="text-xs text-inherit">{{ setBudgetName(value.budgetType) }}</p>
      </div>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    budgetTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      edit: false,
    };
  },
  methods: {
    // 获取预算类型的名称
    setBudgetName(budgetType) {
      return this.budgetTypes.find((v) => v.key == budgetType)?.value;
    },
    editBudget() {
      this.edit = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    changeBudget() {
      this.value.budgetAmount = (this.value.budgetAmount / 1).toFixed(2);
      this.$emit('input', this.value);
    },
  },
};
</script>
<style lang="scss" scoped></style>
