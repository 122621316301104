<template>
  <span>
    <span>
      <div v-if="edit">
        <el-input
          v-model="value.bidAmount"
          @change="changeBid"
          ref="input"
          @blur="edit = false"
          type="number"
        >
          >
        </el-input>
      </div>
      <div v-else>
        <p>
          {{ value.bidAmount }}
          <el-button
            type="text"
            icon="el-icon-edit"
            @click="editBid"
            v-if="!edit && value.bidStrategy != 'AUTO_BID'"
          ></el-button>
        </p>
        <p class="text-xs text-inherit">
          {{ this.setBidName(value.bidStrategy) }}
        </p>
      </div>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    bidStrategys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      edit: false,
    };
  },
  methods: {
    // 获取预算类型的名称
    setBidName(bidStrategy) {
      return this.bidStrategys.find((v) => v.key == bidStrategy)?.value;
    },
    editBid() {
      this.edit = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    changeBid() {
      this.value.bidAmount = (this.value.bidAmount / 1).toFixed(2);
      this.$emit('input', this.value);
    },
  },
};
</script>
<style lang="scss" scoped></style>
