<template>
  <div>
    <div class="nameBtn">
      <el-tooltip
        effect="dark"
        content="批量开启广告组"
        placement="top-start"
      >
        <el-button
          size="mini"
          @click="editStatus('ACTIVE')"
          :disabled="!selectList.length"
        >
          开启
        </el-button>
      </el-tooltip>
      <el-tooltip
        effect="dark"
        content="批量暂停广告组"
        placement="top-start"
      >
        <el-button
          size="mini"
          @click="editStatus('PAUSED')"
          :disabled="!selectList.length"
        >
          暂停
        </el-button>
      </el-tooltip>
      <el-tooltip
        effect="dark"
        content="批量修改广告组名称"
        placement="top-start"
      >
        <el-button
          size="mini"
          @click="editName('adset')"
          type="text"
          :disabled="!selectList.length"
        >
          <el-image
            :src="icons.editGroup"
            alt=""
            class="w-6 h6"
          ></el-image>
        </el-button>
      </el-tooltip>
      <el-tooltip
        effect="dark"
        content="批量修改广告名称"
        placement="top-start"
      >
        <el-button
          size="mini"
          @click="editName('ad')"
          type="text"
          :disabled="!selectList.length"
        >
          <el-image
            :src="icons.editAd"
            alt=""
            class="w-6 h6"
          ></el-image>
        </el-button>
      </el-tooltip>
      <el-tooltip
        effect="dark"
        content="批量修改广告组排期"
        placement="top-start"
      >
        <el-button
          size="mini"
          @click="editDate"
          type="text"
          :disabled="!selectList.length"
        >
          <el-image
            :src="icons.editDate"
            alt=""
            class="w-8 h8"
          ></el-image>
        </el-button>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selectList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      icons: {
        editTimes: require('@/assets/icon/editTimes.png'),
        editDate: require('@/assets/icon/editDate.png'),
        editAd: require('@/assets/icon/editAd.png'),
        editGroup: require('@/assets/icon/editGroup.png'),
      },
    };
  },
  methods: {
    editStatus(status) {
      this.$emit('editStatus', status);
    },
    editDate() {
      this.$emit('editDate');
    },
    editName(level) {
      this.$emit('editName', level);
    },
  },
};
</script>
<style lang="scss" scoped>
.nameBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
