<template>
  <div>
    <el-dialog
      title="批量编辑广告组排期"
      :visible="editDateShow"
      width="700px"
      @close="cancle"
    >
      <date v-model="dateObj"></date>
      <div slot="footer">
        <el-button @click="cancle">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import date from './date.vue';
export default {
  props: {
    editDateShow: false,
  },
  components: {
    date,
  },
  data() {
    return {
      dateObj: {
        endTime: '',
        startTime: '',
      },
    };
  },
  methods: {
    cancle() {
      this.$emit('update:editDateShow', false);
    },
    sure() {
      this.$emit('setDate', this.dateObj);
      this.cancle();
    },
  },
};
</script>
<style lang="scss" scoped></style>
