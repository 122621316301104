<template>
  <span class="inline-flex">
    <div
      v-if="value.level == 'ad'"
      class="flex-none w-10"
    >
      <el-image
        :src="value.creative.mediaUrl"
        fit="contain"
        v-if="value.creative.mediaType == 'IMAGE'"
      ></el-image>
      <video
        :src="value.creative.mediaUrl"
        v-else
      ></video>
    </div>
    <div class="flex-1 ml-1">
      <span
        v-if="!edit"
        class="align-middle"
      >
        {{ value.name }}</span
      >
      <el-input
        v-model="value.name"
        placeholder=""
        @change="changeName"
        @blur="edit = false"
        ref="input"
        v-else
      ></el-input>
      <el-button
        type="text"
        icon="el-icon-edit"
        @click="editName"
        v-if="!edit"
      ></el-button>
    </div>
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      edit: false,
    };
  },
  methods: {
    editName() {
      this.edit = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    changeName() {
      this.$emit('input', this.value);
    },
  },
};
</script>
<style lang="scss" scoped></style>
